<template>
    <div class="app">
        <!-- 轮播图 -->
        <div class="app-content">
            <el-carousel height="38.4375rem" indicator-position="none">
                <el-carousel-item>
                <img class="msgPic" src="../../../assets/allImage/report/toutu_xinfangjubaozhinan@1x.png" alt="" />
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="appline">
            <div class="muns_box">
            <!-- 左边导航 -->
                <div class="muns_Left">
                    <img
                    class="pic"
                    src="../../../assets//allImage//companyProfile/icon_home@2x.png"
                    alt=""
                    />
                    <span style="font-size: 0.9375rem;cursor: pointer;"  @click="GoHome">首页</span>
                    <span class="leftRnder">
                    <i class="iconfont icon-xiangyoujiantou"></i>
                    信访举报指南
                    </span>
                </div>
            </div>
        </div>
        <div class="appReport">
            <!-- 新闻详情 -->
            <div class="report-content">
                <h4>信访举报指南</h4>
                <div class="heads">
                    <div class="timeData">
                    <span>时间：</span>
                    <span class="commSyl">2019-12-31</span>
                    </div>
                    <div class="pageView">
                    <span> 浏览次数：</span>
                    <span class="commSyl"> 7180</span>
                    </div>
                    <div>
                    <span>信息来源：</span>
                    <span class="commSyl">广西壮族自治区交通运输厅</span>
                    </div>
                </div>
                <div class="listSon">
                    <div>
                             受理范围
         1.根据《信访工作条例》、《纪检监察机关处理检举控告工作规则》等相关规定，受理由中共广西捷通高速科技有限公司委员会管理的党员、党组织违反党章和党内法规，违反党的路线、方针、政策和决议，利用职权谋取私利和其他败坏党风行为的检举、控告。
         2.按照干部管理权限及企业管理相关规定，受理由广西捷通高速科技有限公司管理的对象违反国家法律、法规、政策和决定、命令以及违反工作纪律等行为的检举、控告。
         3.对中共广西捷通高速科技有限公司委员会党风廉政建设和反腐败工作工作的意见和建议。
         检举、控告人的义务
         检举、控告的内容应当客观真实，并提供具体事实材料和尽量提供相关证据（照片、录音、视频等），不得捏造、歪曲事实，不得诬告、陷害他人。提倡署实名举报，署实名举报请详细填写联系方式。
         举报方式
         来信或来访地址：
         1.广西南宁市青秀区民族大道188号广西捷通高速科技有限公司综合管理部
         邮编：530022
         举报电话：0771-5627018（非节假日正常上班时间）
         举报邮箱:jtxfjb2022@163.com（24小时开放）
         2.广西南宁市青秀区民族大道152号铁投大厦25楼2521室广西交通投资集团第八派驻纪检监察组（负责监督联系广西捷通高速科技有限公司）
         邮编：530022
         举报电话：0771-3197109（非节假日正常上班时间）
         举报邮箱：jtd8jjz@163.com（24小时开放）
         我们将严格依法保护检举、控告人依法行使的检举、控告权利。
 
                    </div>
                </div>
            </div>
        </div>
    <BottomField></BottomField>

    </div>
</template>

<script>
import BottomField from '@/layout/components/HomePage/BottomField/index.vue';
export default {
    components: {
        BottomField,
    },
    name: 'JietongofficialwebsiteuiIndex',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        GoHome(){
        	this.$router.push("/layout/index")
        },
    },
};
</script>

<style lang="scss" scoped>
.app {
  width: 100%;
  .app-content {
    width: 100%;
    .msgPic {
      width: 100%;
    }
  }
  .appline {
    width: 100%;
    height: 3.125rem;
    background-color: #4b4843;
    display: flex;
    justify-content: center;
    .muns_box {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .muns_Left {
        width: 25rem;
        height: 100%;
        align-items: center;
        display: flex;
        font-size: 0.9375rem;
        color: #c1c1c1;
        .pic {
            width: 0.9375rem;
            vertical-align: middle;
            margin-right: 0.1875rem;
        }
        .leftRnder {
            font-size: 0.9375rem;
            margin-left: 0.3125rem;
            i {
            font-size: 0.9375rem;
            }
        }
        }
    }
    }
    .appReport {
    width: 100%;
    background-color: #f5f5f5;
    padding: 1.25rem 0;
    .report-content {
        width: 60%;
        padding: 2.5rem 0.9375rem;
        background-color: #fff;
        border-radius: 0.25rem;
        margin: 0.625rem auto;
        h4 {
        width: 100%;
        height: 2.1875rem;
        line-height: 2.1875rem;
        }
        .heads {
        width: 100%;
        height: 2.8125rem;
        border-bottom: 0.0625rem solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75rem;
        .timeData {
            margin-right: 0.9375rem;
        }
        .pageView {
            margin-right: 0.9375rem;
        }
        }
        .listSon {
        width: 100%;
        line-height: 2.1875rem;
        padding: 0.9375rem;
        text-align: left;
        }
    }
    .thePrecedingAndSubsequentArticles {
        width: 80%;
        height: 2.8125rem;
        margin: 0 auto;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .box_left {
        font-size: 0.75rem;
        .son_lt {
            color: #c1c1c1;
        }
        }
        .box_right {
        font-size: 0.75rem;
        .son_Rt {
            color: #c1c1c1;
        }
        }
    }
    }
}
</style>